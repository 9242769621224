import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';

import Navbar from './Navbar.component';
import Footer from './Footer.component';

interface PageProps {
  children: React.ReactNode;
}

function Page({ children }: PageProps) {
  const location = useLocation();
  const shouldRedirect = process.env.REACT_APP_SURVEY_ENDED === '1';
  const targetRoute = '/end';

  if (shouldRedirect && location.pathname !== targetRoute) {
    return <Navigate to={targetRoute} />;
  }

  return (
    <div className="d-flex flex-column h-100 overflow-x-hidden">
      <div id="loading" className="position-fixed h-100 hidden">
        <div className="h-100 d-flex d-flex align-items-center justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Načítání...</span>
          </div>
        </div>
      </div>
      <Navbar />
      <main className="flex-shrink-0">
        <div id="wrapper" className="container m-auto pt-5">
          {children}
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Page;
