import axios from 'axios';
import { ICompany } from '../interface/ICompany';

console.log('envs', process.env);

export const apiBaseUrl =
  process.env.REACT_APP_API_SERVER_URL || 'https://dev.absolventi.cz/';

const axiosApi = axios.create({
  baseURL: apiBaseUrl,
});

axiosApi.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export enum VerificationResponseCode {
  NOT_FOUND = 'NOT_FOUND',
  ALREADY_VERIFIED = 'ALREADY_VERIFIED',
  OK = 'OK',
}

const convertToFormData = (data: Record<string, any>) => {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => {
        formData.append(key, v);
      });
    } else if (value instanceof File) {
      formData.append(key, value, value.name);
    } else {
      formData.append(key, value);
    }
  });

  return formData;
};

const api = {
  getCompanyByIco: async (ico: string) => {
    const { data } = await axiosApi.get(
      `/fulltext/companies/ico/?text=${ico.replace(/\s/g, '')}&limit=1&page=0`
    );

    if (
      !data.success ||
      !data.data ||
      !data.data.values ||
      !data.data.values.length
    ) {
      throw new Error('Failed to retrieve company data');
    }

    return data.data.values[0] as ICompany;
  },
  getCompanyByEnpsId: async (enpsId: string) => {
    const { data } = await axiosApi.get(
      `/fulltext/companies/enps/?text=${enpsId.replace(/\s/g, '')}&limit=1&page=0`
    );

    if (
      !data.success ||
      !data.data ||
      !data.data.values ||
      !data.data.values.length
    ) {
      throw new Error('Failed to retrieve company data');
    }

    return data.data.values[0] as ICompany;
  },
  postEnps: async (input: Record<string, any>) => {
    const formData = convertToFormData(input);

    const { data } = await axiosApi.post('/apiv2/enps', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (!data.status) {
      if (data.code === 'ALREADY_EXIST') return null;

      throw new Error(data.code);
    }

    return data;
  },
  postEnpsVerification: async (
    token: string
  ): Promise<VerificationResponseCode> => {
    const { data } = await axiosApi.post(`/apiv2/enps/verify/${token}`);

    if (!data.status) {
      throw new Error(data.code);
    }

    return data.code ?? VerificationResponseCode.OK;
  },
  postResultSubscription: async (input: Record<string, any>) => {
    const { data } = await axiosApi.post(`/apiv2/subscribe`, input);

    if (!data.status) {
      return false;
    }

    return true;
  },
};

export default api;
